import React, { useEffect, useState } from 'react';
import { characterMap } from '../lib/characterMap';

const CharacterThumb = ({
  name
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    loadImage();
  }, []);

  const imagePathColor = characterMap[name].thumbImgColor;
  const imagePathBW = characterMap[name].thumbImgBW;
  const thumbColor = require(`../images/characters/thumbnails/${imagePathColor}`);
  const thumbBW = require(`../images/characters/thumbnails/${imagePathBW}`);

  const showColor = (name) => {
    document.querySelector(`.${name}`).src = thumbColor;
  }

  const showSketch = (name) => {
    document.querySelector(`.${name}`).src = thumbBW;
  }

  const loadImage = () => {
    if (imageLoaded) return;

    const imageUrl = require(`../images/characters/thumbnails/${imagePathColor}`);

    let imgContainer = document.querySelector(`.${name}-image-container`);

    if (!imgContainer) return;

    imgContainer.classList.add("loading");
    let preloadedCover = document.createElement("img");
    preloadedCover.src = imageUrl;
    preloadedCover.classList.add(`${name}`);
    preloadedCover.alt = `Bust of ${name}`;

    preloadedCover.addEventListener('mouseover', () => showSketch(`${name}`));
    preloadedCover.addEventListener('mouseleave', () => showColor(`${name}`));

    preloadedCover.addEventListener('load', () => {
      imgContainer.classList.remove("loading");
      imgContainer.appendChild(preloadedCover);
    });

    preloadedCover.removeEventListener('load', () => console.log('Aw, Yeah!'));

    setImageLoaded(true);
  }

  return (
    <React.Fragment>
      <div className={`${name}-image-container image-container loading`}>
        {/* //*img dynamically inserted for blur-up effect */}
      </div>
      <div>
        <h4 className="grid-character-name">{name}</h4>
      </div>
    </React.Fragment>
  )
}

export default CharacterThumb;