import React from 'react';
import { Link } from 'gatsby';
import CharacterThumb from '../components/characterThumb';

const CastGrid = ({ doubleColumn }) => {
  return (
    <React.Fragment>
      <div className={doubleColumn ? "intro double-col" : "intro"}>
        <span className="mobile">
          As a budding writer, <span className="highlight-yellow gray-drop-shadow">Sean thought it'd be cool to
            have a concept artist do up some of the characters from his
            first book</span>. After scouring the interwebs for what seemed an age, he came across a
          rendition of a cyberpunk girl toting a very large gun.
        </span>
        <span className="mobile">
          The letters '<span className="highlight-yellow gray-drop-shadow">FU36-ESM</span>' were
          scrawled down the length of the barrel, a subtle reference to one of his favorite
          <i> <span className="highlight-yellow gray-drop-shadow">Marvin the Martian</span></i> episodes, and Sean knew he had found his artist.
          What you see on this site are the results of that fated search &mdash; twelve characters from the D-Evolution Universe,
          painted by the incomparable <a className="gray-drop-shadow" href="https://www.mattdixon.co.uk" target="_blank" rel="noopener noreferrer">Matt Dixon</a>.
        </span>
      </div>
      <section className="CastGrid hero dark">
        <div className="grid-container">
          <div className="cast-grid">
            <Link to='/Malo'><CharacterThumb name='Malo' /></Link>
            <Link to='/Talfus'><CharacterThumb name='Talfus' /></Link>
            <Link to='/Dezmara'><CharacterThumb name='Dezmara' /></Link>
            <Link to='/Killikbar'><CharacterThumb name='Killikbar' /></Link>
            <Link to='/Otto'><CharacterThumb name='Otto' /></Link>
            <Link to='/Fellini'><CharacterThumb name='Fellini' /></Link>
            <Link to='/Abalias'><CharacterThumb name='Abalias' /></Link>
            <Link to='/Rilek'><CharacterThumb name='Rilek' /></Link>
            <Link to='/Blink'><CharacterThumb name='Blink' /></Link>
            <Link to='/Gyumak'><CharacterThumb name='Gyumak' /></Link>
            <Link to='/Graale'><CharacterThumb name='Graale' /></Link>
            <Link to='/Blangaris'><CharacterThumb name='Blangaris' /></Link>
          </div>
        </div>
      </section>
    </React.Fragment >
  )
}

export default CastGrid;