import React from 'react';
import { Helmet } from 'react-helmet';
import Head from '../components/head';
import Layout from '../components/layout';
import CastGrid from '../components/castGrid';


const CastPage = () => {
  const baseSiteUrl = 'https://authorseanallen.com';
  const PAGE = "Cast"
  return (
    <Layout>
      <Head title={PAGE} />
      <Helmet>
        <meta name="description"
          content="Character Illustrations,
          Books: The D-Evolution,
          Creator: Sean Allen,
          Illustrator: Matt Dixon" />
        <link rel="canonical" href={`${baseSiteUrl}/cast`} />
      </Helmet>
      <CastGrid doubleColumn />
    </Layout>
  )
}

export default CastPage;